import { combineReducers } from "redux";
import alert from "../utils/Alert.component/store/alert.reducer";
import auth from "../components/Auth/store/auth.reducer";
import device from "../components/Device/store/device.reducer";
import dashboard from "../components/Dashboard/store/dashboard.reducer";
import report from "../components/Report/store/report.reducer";


export default combineReducers({
  alert,
  auth,
  device,
  dashboard,
  report
});
