import {
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGOUT,
  ADMIN_UPDATE_SUCCESS,

} from './auth.type';

const initialState = {
  isAuthenticated: localStorage.getItem('token') ? true : false,
  user: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        user: payload
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    case ADMIN_UPDATE_SUCCESS:
      return {
        ...state,
        user: payload,
      };            
    default:
      return state;
  }
}
