import { USER_LOADED, LOGOUT } from "./auth.type";
import { setAuthToken } from "../../../services/api.services";
import * as AuthService from "../../../services/auth.services";
import { customeContenttoaster } from "../../../utils/Toaster";
// Login User
export const login = (email, password) => async (dispatch) => {
  const creds = { email, password };
  try {
    const result = await AuthService.login(creds);
    await setSession(result);
    await dispatch(loadUser());
    return { success: result };
  } catch (err) {
    customeContenttoaster(err.message, "danger");
  }
};

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    await setAuthToken(localStorage.token);
    try {
      const res = await AuthService.getCurrentUser();
      dispatch({
        type: USER_LOADED,
        payload: res,
      });
    } catch (err) { }
  }
};

export const setSession = async (authObj) => {
  if (!authObj || !authObj.token) {
    clearSession();
    return;
  }
  localStorage.setItem("token", authObj.token);
  localStorage.setItem("user", JSON.stringify(authObj));
};

export const clearSession = async () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

// Logout / Clear Profile
export const logout = () => async (dispatch) => {
  await AuthService.logout();
  clearSession();
  dispatch({ type: LOGOUT });
};

export const forgetPassword = (email) => async (dispatch) => {
  try {
    const res = await AuthService.forgetPassword({ email });
    return res;
  } catch (err) {
    customeContenttoaster(err.message, "danger");
  }
};

export const resetPassword = (data) => async (dispatch) => {
  try {
    const res = await AuthService.resetPassword(data);
    customeContenttoaster(res.message, "success");
    return res;
  } catch (err) {
    customeContenttoaster(err.message, "danger");
  }
};

export const changePass = (data) => async (dispatch) => {
  try {
    const res = await AuthService.changePass(data);
    customeContenttoaster(res.message, "success");
  } catch (err) {
    customeContenttoaster(err.message, "danger");
  }
};
