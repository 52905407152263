import React from "react";
import { store as stores } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import success from "../assets/img/tick.svg";
import error from "../assets/img/warning.svg";

export const customeContenttoaster = (message, getVariant, duration, icon) => {
  let variantIcon = getVariant === "success" ? success : error;
  stores.addNotification({
    // message: message,
    content: (
      <div className={`custom-notification notification-custom-${getVariant}`}>
        <div className="notification-custom-icon">
          <img src={variantIcon} alt="variant" width="16" />
          {/* circle-x */}
        </div>
        <div className="notification-custom-content">
          <p className="notification-message">{message}</p>
        </div>
        <div className="notification-close">
          <span className="close-notification">&times;</span>
        </div>
      </div>
    ),
    // type: getVariant,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000,
      // onScreen: true,
    },
  });
};
