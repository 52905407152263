import { alert } from "./alert.types";

export const showLoading = () => ({
  type: alert.SHOW_LOADER
});

export const showListLoader = () => ({
  type: alert.SHOW_LIST_LOADER
});

export const hideListLoader = () => ({
  type: alert.HIDE_LIST_LOADER
});

export const showSuccessAlert = msg => dispatch => {
  dispatch({
    type: alert.SHOW_SUCCESS_MESSAGE,
    payload: msg
  });
  setTimeout(() => dispatch({ type: alert.CLEAR_ALERT }), 2000);
};

export const showErrorAlert = err => dispatch => {
  dispatch({
    type: alert.SHOW_ERROR_MESSAGE,
    payload: err
  });
  setTimeout(() => dispatch({ type: alert.CLEAR_ALERT }), 2000);
};

export const clearAlert = () => ({
  type: alert.CLEAR_ALERT
});
