import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import history from "./history";
import "./App.css";
import "./App.scss";
import { loadUser } from "./components/Auth/store/auth.action";
import { PublicRoutes, PrivateRoutes } from "./routes";
import { AppLoader } from "./utils/Loader/Loader";
import store from "./store";
import ReactNotification from "react-notifications-component";
class App extends Component {
  componentWillMount() {
    store.dispatch(loadUser());
  }
  render() {
    return (
      <Router history={history}>
        <React.Suspense fallback={<AppLoader />}>
       
          <ReactNotification className="toasterContent" />
          {this.props.isAuthenticated ? (
            <PrivateRoutes
              role={this.props.user ? this.props.user.role : null}
            />
          ) : (
            <PublicRoutes />
          )}
        </React.Suspense>
      </Router>
    );
  }
}

const mapStateToProps = ({ auth, alert }) => {
  return {
    isAuthenticated: auth.isAuthenticated,
    user: auth.user,
  };
};

export default connect(mapStateToProps)(App);
