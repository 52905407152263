export const API = {
  AUTH: {
    POST: "/login",
    DELETE: "/logout",
    FORGOT_PWD: "/auth/forgot-password",
    RESET_PWD: "/auth/reset-password",
    UPDATE_PROFILE: "/sub-admin/profile/update",
    CHANGE_PWD: "/auth/change-password",
    CURRENT_USER: "/current-user",
  },
  DEVICE: {
    LIST: "/device",
    ADD: "/device",
    GET: "/device/",
    UPDATE: "/device/",
    DELETE: "/device/",
  },
  DASHBOARD: {
    LIST: "/dashboard",
    ADD: "/dashboard",
    GET: "/dashboard/",
    UPDATE: "/dashboard/",
    DELETE: "/dashboard/",
  },
  REPORT: {
    LIST: "/report",
    ADD: "/report",
    GET: "/report/",
    UPDATE: "/report/",
    DELETE: "/report/",
  },
  USERDASH: {
    VERIFY: "/user/verify",
    DASHBOARD: "/dashboard/",
    TIME: "/getDistributeTime/",
    DEVICES: "/getDevices/",
    BUTTON_STATS: "/getButtonStats/",
    DEVICE_USAGE: "/getDeviceUsage/",
    FRAUD_RATE: "/getFraudRate/",
    SATISFY_RATING: "/getSatisfyRating/",
    DEVICE_SATISFACTION: "/getDeviceSatisfaction/",
    BUTTON: "/getButtonStats/",
  },
  TAGS: {
    GET: "/tags",
  },
};
