import { dashboard } from "./dashboard.types";

export default function (state = { listLoading: true }, action) {
  const { type, payload } = action;
  switch (type) {
    case dashboard.GETALL_DASHBOARD_SUCCESS:
      delete state.currentDashboard;
      if (payload.docs) {
        return {
          dashboardList: payload.docs,
          totalRows: payload.totalDocs,
          listLoading: false,
        };
      }
      return {
        dashboardList: payload,
        listLoading: false,
      };
    case dashboard.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        currentDashboard: payload,
      };

    case dashboard.CATEGORY_LIST_LOADING:
      return {
        ...state,
        listLoading: payload,
      };
    case dashboard.GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: payload,
      };
    default:
      return state;
  }
}
