import { device } from "./device.types";

export default function (state = { listLoading: true }, action) {
  const { type, payload } = action;
  switch (type) {
    case device.GETALL_DEVICE_SUCCESS:
      delete state.currentDevice;
      if (payload.docs) {
        return {
          deviceList: payload.docs,
          totalRows: payload.totalDocs,
          listLoading: false,
        };
      }
      return {
        parentCategoryList: payload,
        listLoading: false,
      };
    case device.GET_DEVICE_SUCCESS:
      return {
        ...state,
        currentDevice: payload,
      };
    case device.DEVICE_LIST_LOADING:
      return {
        ...state,
        listLoading: payload,
      }
    case device.GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: payload,
      }
    default:
      return state;
  }
}
