import { apiClient, composeUrl, config } from "./api.services";
import { API } from "../constants/api.constants";

export const login = async (creds) => {
  return apiClient.post(composeUrl(API.AUTH.POST), creds);
};

export const getCurrentUser = async () => {
  return apiClient.get(composeUrl(API.AUTH.CURRENT_USER));
};

export const logout = async () => {
  return apiClient.delete(composeUrl(API.AUTH.DELETE));
};

export const forgetPassword = async (email) => {
  return apiClient.post(composeUrl(API.AUTH.FORGOT_PWD), email);
};

export const resetPassword = async (payload) => {
  return apiClient.post(composeUrl(API.AUTH.RESET_PWD), payload);
};

export const updateProfile = async (payload) => {
  return apiClient.post(composeUrl(API.AUTH.UPDATE_PROFILE), payload, config);
};

export const changePass = async (payload) => {
  return apiClient.post(composeUrl(API.AUTH.CHANGE_PWD), payload);
};

