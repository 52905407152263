import { report } from "./report.types";

export default function (state = { listLoading: true }, action) {
  const { type, payload } = action;
  switch (type) {
    case report.GETALL_REPORT_SUCCESS:
      delete state.currentReport;
      if (payload.docs) {
        return {
          reportList: payload.docs,
          totalRows: payload.totalDocs,
          listLoading: false,
        };
      }
      return {
        reportList: payload,
        listLoading: false,
      };
    case report.GET_REPORT_SUCCESS:
      return {
        ...state,
        currentReport: payload,
      };

    case report.CATEGORY_LIST_LOADING:
      return {
        ...state,
        listLoading: payload,
      };
    case report.GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: payload,
      };
    default:
      return state;
  }
}
