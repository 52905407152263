import axios from "axios";
import { logout } from "../components/Auth/store/auth.action";
import store from "../store";
import history from "../history";
import {
  showErrorAlert,
  clearAlert,
} from "../utils/Alert.component/store/alert.actions";

export const apiClient = axios.create();
apiClient.defaults.withCredentials = true;
const { protocol } = window.location;

export const apiEndpoint = `${protocol}//${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_ENDPOINT}`;

export const composeUrl = (route, params = {}) => {
  let url =`${apiEndpoint}${route}`;
  Object.keys(params).forEach((key) => {
    url = url.replace(`:${key}`, params[key]);
  });
  return url;
};

export const setAuthToken = async (token) => {
  if (token) {
    apiClient.defaults.headers.common["Authorization"] = "bearer " + token;
  } else {
    delete apiClient.defaults.headers.common["Authorization"];
  }
};

apiClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(logout());
      store.dispatch(showErrorAlert(error.response.data.message));
      setTimeout(() => {
        store.dispatch(clearAlert());
      }, 1000);
      history.push("/");
    }
    throw error.response ? error.response.data : error;
  }
);

export const config = {
  headers: {
    "content-type": "multipart/form-data",
  },
};
