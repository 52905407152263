import { alert } from "./alert.types";

export default function(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case alert.SHOW_LOADER:
      return {
        ...state,
        loading: true
      };
    case alert.SHOW_LIST_LOADER:
      return {
        ...state,
        listLoading: true
      };
    case alert.HIDE_LIST_LOADER:
      return {
        ...state,
        listLoading: false
      };
    case alert.SHOW_SUCCESS_MESSAGE:
      return {
        ...state,
        success: payload,
        loading: false
      };
    case alert.SHOW_ERROR_MESSAGE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case alert.CLEAR_ALERT:
      return {
        ...state,
        loading: false,
        success: "",
        error: ""
      };
    default:
      return state;
  }
}
