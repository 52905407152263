import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
const Homepage = React.lazy(() => import("./components/HomePage/HomePage"));

const Login = React.lazy(() => import("./components/Auth/Login/Login"));

const DefaultHeader = React.lazy(() =>
  import("./components/DefaultLayout/DefaultHeader")
);
const DefaultNav = React.lazy(() =>
  import("./components/DefaultLayout/DefaultNav")
);

const DeviceList = React.lazy(() =>
  import("./components/Device/DeviceList/DeviceList")
);
const AddEditDevice = React.lazy(() =>
  import("./components/Device/AddEditDevice/AddEditDevice")
);

const DashboardList = React.lazy(() =>
  import("./components/Dashboard/DashboardList/DashboardList")
);
const AddEditDashboard = React.lazy(() =>
  import("./components/Dashboard/AddEditDashboard/AddEditDashboard")
);

const ReportList = React.lazy(() =>
  import("./components/Report/ReportList/ReportList")
);
const AddEditReport = React.lazy(() =>
  import("./components/Report/AddEditReport/AddEditReport")
);


export const PrivateRoutes = ({ role }) => {
  return (
    <div className="app">
      <DefaultHeader />
      <div className="app-body">
        <DefaultNav />
        <main className="main">
          <Container fluid>
            <Suspense>
              <Switch>
                <Route exact path="/homepage" component={Homepage} />
                <Route exact path="/device" component={DeviceList} />
                <Route exact path="/device/add" component={AddEditDevice} />
                <Route
                  exact
                  path="/device/edit/:deviceId"
                  component={AddEditDevice}
                />
                <Route exact path="/dashboard" component={DashboardList} />
                <Route
                  exact
                  path="/dashboard/add"
                  component={AddEditDashboard}
                />
                <Route
                  exact
                  path="/dashboard/edit/:dashboardId"
                  component={AddEditDashboard}
                />
                <Route exact path="/report" component={ReportList} />
                <Route exact path="/report/add" component={AddEditReport} />
                <Route
                  exact
                  path="/report/edit/:reportId"
                  component={AddEditReport}
                />

                <Route render={() => <Redirect to="/homepage" />} />
              </Switch>
            </Suspense>
          </Container>
        </main>
      </div>
    </div>
  );
};
export const PublicRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        name="Login Page"
        render={(props) => <Login {...props} />}
      />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};
